<template>
  <section class="form-wrapper">
    <div v-if="showForm">
      <van-form ref="form">
        <van-field
          readonly
          disabled
          v-model="form.applyName"
          name="applyName"
          label="姓名"
          placeholder="请输入姓名"
          required
        />
        <van-field-select
          v-model="form.idCardType"
          :options="idCardTypes"
          :fieldProps="{
            label: '证件类型',
            required: true,
            readonly: true,
            disabled: true,
            placeholder: '请选择证件类型',
          }"
        ></van-field-select>
        <van-field
          readonly
          disabled
          v-model="form.idCardNum"
          name="idCardNum"
          label="证件号码"
          placeholder="请输入证件号码"
          required
        />
        <van-field-area
          v-model="form.domicile"
          :fieldProps="{
            required: true,
            label: '户籍地址',
            placeholder: '请选择户籍地址',
          }"
          defaultField=""
        />

        <van-field
          v-model="form.phone"
          label="手机号"
          placeholder="请输入手机号"
          required
          maxlength="11"
        />

        <van-field
          v-model="form.industryTalentType"
          label="产业人才类别"
          placeholder="请输入产业人才类别"
          required
          disabled
        />
        <van-field
          v-model="form.bankId"
          name="bankId"
          label="社会保障卡开户银行"
          placeholder="请输入社会保障卡开户银行"
          required
          maxlength="100"
        />
        <van-field
          v-model="form.bankAccount"
          name="bankAccount"
          label="社会保障卡金融账号"
          placeholder="请输入社会保障卡金融账号"
          required
          maxlength="20"
        />
        <van-field
          v-model="form.preYearSalary"
          name="preYearSalary"
          label="上年度年度薪资(元)"
          placeholder="请输入上年度年度薪资(元)"
          required
          maxlength="20"
        />
        <van-field
          v-model="form.preYearRatal"
          name="preYearRatal"
          label="上年度工资薪金纳税额(元)"
          placeholder="请输入上年度工资薪金纳税额(元)"
          required
          maxlength="20"
        />
        <van-field
          v-model="form.workCmpy"
          name="workCmpy"
          label="工作单位"
          placeholder="请输入工作单位"
          required
          maxlength="100"
        />
      </van-form>
    </div>
    <div v-else>
      <van-empty description="暂无产业人才认证,无法办理" />
    </div>
  </section>
</template>

<script>
import formMixin from "../mixin";
import Schema from "async-validator";
import { gccrcsbhy } from "@/api/policy/apply";

export default {
  name: "high-talent-devote",
  mixins: [formMixin],
  data() {
    return {
      showForm: false,
      form: {
        applyName: "", //姓名
        idCardType: "居民身份证", //证件类型
        idCardNum: "", //证件号码
        domicile: [], //户籍地址
        phone: "",
        industryTalentType: "", //高层次人才类别
        bankId: "", //社会保障卡开户银行
        bankAccount: "", //社会保障卡金融账号
        workCmpy: "", //工作单位
        preYearSalary: "", //薪资
        preYearRatal: "", //纳税额
      },
      formRules: {
        phone: [
          {
            type: "string",
            required: true,
            message: "请输入手机号",
          },
          {
            message: "手机号格式不正确",
            pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
          },
        ],
        domicile: {
          type: "array",
          required: true,
          message: "请选择户籍地址",
        },
        bankId: {
          required: true,
          message: "请输入开户银行",
        },
        bankAccount: {
          required: true,
          message: "请输入金融账号",
        },
        industryTalentType: {
          required: true,
          message: "请输入人才类别",
        },
        preYearSalary: [
          {
            required: true,
            message: "请输入上年度薪资",
          },
          {
            message: "年度薪资过大或格式错误",
            pattern: /^100000000$|^([0-9]{0,8})(\.(\d){0,2})?$/,
            type: "string",
          },
        ],
        preYearRatal: [
          {
            required: true,
            message: "请输入上年度薪资纳税额",
          },
          {
            message: "纳税额过大或格式错误",
            pattern: /^100000000$|^([0-9]{0,8})(\.(\d){0,2})?$/,
            type: "string",
          },
        ],
        workCmpy: {
          required: true,
          message: "请输入工作单位",
        },
      },
      idCardTypes: [
        {
          value: "居民身份证",
          label: "居民身份证",
        },
      ],
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.global.userInfo;
    },
  },
  created() {
    this.initFormValidator();
    if (this.userInfo) {
      this.authCheck();
    }
  },
  watch: {
    userInfo() {
      if (this.userInfo) {
        this.authCheck();
      }
    },
  },
  methods: {
    // 判断产业人才
    authCheck() {
      const talentType = "0"; //产业人才
      this.$store
        .dispatch("policy/getUserTalentTypesByIdcard", {
          idCard: this.userInfo.idCard,
          userName: this.userInfo.rnName,
          type: talentType,
        })
        .then((res) => {
          if (res) {
            let strs = [];
            strs = res.map((item) => {
              return item.typeName;
            });
            let libraryStr = strs.join("/");

            this.form.industryTalentType = libraryStr;
            this.showForm = true;
          } else {
            this.authCheckFail();
          }
        })
        .catch((err) => {
          this.authCheckFail();
        });
    },
    authCheckFail() {
      this.$toast("暂无产业人才认证,无法办理");
      this.$emit("authCheck", false);
      this.showForm = false;
    },
    /*
     *@des: 初始化校验
     */
    initFormValidator() {
      this.formValidator = new Schema(this.formRules);
    },
    /*
     *@des: 校验表单
     */
    validate() {
      return new Promise((resolve, reject) => {
        this.formValidator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(async () => {
            let [proCode, cityCode, countyCode] = this.form.domicile;
            resolve({
              ...this.form,
              proCode,
              cityCode,
              countyCode,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style>
</style>